@import "./_variables.scss";
@import "./mixins/breakpoints.scss";

.who-section {
  background-color: white;
  padding-top: 100px;
  padding-bottom: 50px;
}

.studio-gallery {
  margin-top: 50px;

  .image {
    margin-bottom: 20px;
  }
}

@include breakpoint-min(tablet) {
  .studio-gallery {
    margin-top: 100px;
  }
}

@include breakpoint-min(desktop) {
  .who-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}