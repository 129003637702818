@import "../_variables.scss";

@mixin breakpoint-min($size) {
  $min: map-get($breakpoints, $size);
  @media only screen and (min-width: $min) {
    @content;
  }
}

@mixin breakpoint-max($size) {
  $n: index(map_keys($breakpoints), $size);

  @if ($n < length($breakpoints)) {
    $next: nth(map_keys($breakpoints), $n + 1);
    $max: map-get($breakpoints, $next);
    @media only screen and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin breakpoint-only($size) {
  $n: index(map_keys($breakpoints), $size);

  @if ($n < length($breakpoints)) {
    $this: nth(map_keys($breakpoints), $n);
    $next: nth(map_keys($breakpoints), $n + 1);
    $min: map-get($breakpoints, $this);
    $max: map-get($breakpoints, $next);
    @media only screen and (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
