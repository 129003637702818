@import "../_colors.scss";
@import "../_variables.scss";
@import "../mixins/breakpoints.scss";

@each $name, $color in $colors {
  .is-color-#{$name} {
    color: $color;
  }

  .is-background-color-#{$name} {
    background-color: $color;
  }
}

// Text

@each $size in $sizes {
  $i: index($sizes, $size);
  .is-size-#{$i} {
    font-size: $size !important;
  }
}

@each $breakpoint, $values in $breakpoints {
  @include breakpoint-min($breakpoint) {
    @each $size in $sizes {
      $i: index($sizes, $size);
      .is-size-#{$i}-#{$breakpoint} {
        font-size: $size !important;
      }
    }
  }
}

.is-capitalized {
  text-transform: capitalize !important;
}

.is-lowercase {
  text-transform: lowercase !important;
}

.is-uppercase {
  text-transform: uppercase !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.is-text-center {
  text-align: center !important;
}

// LAYOUT

.is-full-height {
  height: 100vh;
}

@each $breakpoint, $values in $breakpoints {
  @include breakpoint-min($breakpoint) {
    .is-full-height-#{$breakpoint} {
      height: 100vh;
    }
  }
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.display-flex {
  display: flex !important;
}

@each $breakpoint, $values in $breakpoints {
  @include breakpoint-min($breakpoint) {

    .align-items-start-#{$breakpoint} {
      align-items: flex-start !important;
    }

    .align-items-end-#{$breakpoint} {
      align-items: flex-end !important;
    }

    .align-items-center-#{$breakpoint} {
      align-items: center !important;
    }

    .align-items-baseline-#{$breakpoint} {
      align-items: baseline !important;
    }

    .align-items-stretch-#{$breakpoint} {
      align-items: stretch !important;
    }

    .justify-content-start-#{$breakpoint} {
      justify-content: flex-start !important;
    }

    .justify-content-end-#{$breakpoint} {
      justify-content: flex-end !important;
    }

    .justify-content-center-#{$breakpoint} {
      justify-content: center !important;
    }

    .justify-content-between-#{$breakpoint} {
      justify-content: space-between !important;
    }

    .justify-content-around-#{$breakpoint} {
      justify-content: space-around !important;
    }

    .flex-direction-row-#{$breakpoint} {
      flex-direction: row !important;
    }

    .flex-direction-column-#{$breakpoint} {
      flex-direction: column !important;
    }

  }
}

