@import "./mixins/helpers.scss";

.fade-enter {
  @include opacity(0.01);
}

.fade-enter.fade-enter-active {
  @include transition(opacity 300ms);
  opacity: 1;
}

.fade-exit {
  @include opacity(1);
}

.fade-exit.fade-exit-active {
  @include opacity(0.01);
  @include transition(opacity 300ms);
}

// ROTATE

@include keyframes(rotate) {

  from {
    @include transform(rotate(0deg));
  }

  to {
    @include transform(rotate(359deg));
  }

}

// FADE

@include keyframes(fade) {

  from {
    @include opacity(0);
  }

  to {
    @include opacity(1);
  }

}

// FADE UP

@include keyframes(fadeUp) {

  from {
    @include opacity(0);
    @include transform(translate3d(0, 100%, 0));
  }

  to {
    @include opacity(1);
    @include transform(translate3d(0, 0, 0));
  }

}