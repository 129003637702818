@import "./mixins/breakpoints.scss";

.culture-section {
  background-color: white;
  padding-top: 25%;
  padding-bottom: 80px;
  overflow: hidden;
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  .donut {
    position: absolute;
    width: 768px;
    height: 768px;
    bottom: calc(-80% + 384px);
    left: calc(50% - 384px);
  }
}

@include breakpoint-min(landscape) {
  .culture-section {
    padding-top: 150px;
  }
}

@include breakpoint-min(tablet) {
  .culture-section {
    padding-top: 180px;
  }
}

@include breakpoint-min(desktop) {
  .culture-section {
    padding-top: 250px;
    padding-bottom: 150px;
  }
}

@include breakpoint-min(widescreen) {
  .culture-section {
    padding-top: 350px;
    padding-bottom: 200px;
  }
}

@include breakpoint-min(fullhd) {
  .culture-section {
    padding-top: 450px;
  }
}