@import "../_variables.scss";

.image {
  display: block;
  position: relative;

  img {
    display: block;
    height: auto;
    width: 100%;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.is-square,
  &.is-2by1 {
    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      height: 100%;
    }
  }

  &.is-square {
    padding-top: 100%;
  }

  &.is-2by1 {
    padding-top: 50%;
  }

  @each $dimension in $dimensions {
    &.is-#{$dimension}x#{$dimension} {
      height: $dimension * 1px;
      width: $dimension * 1px
    }
  }
}