@import "./_colors.scss";

.donut {
  border-radius: 50%;
  border: 94px solid $backgroundGray;
}

.square {
  background-color: $backgroundGray;
}

.oval {
  padding: 78px;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 50%;

  .oval-middle {
    padding: 78px;
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 50%;
    width: 100%;
    height: 100%;

    .oval-inner {
      background-color: #F4F4F4;
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }
}

.donut-square {
  border: 100px solid #1D1D1D;
}

.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 30px;
  width: 3px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}