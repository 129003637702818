$primary: blue;
$gray: #CDCDCD;
$darkerGray: #5A5A5A;
$backgroundGray: #F8F8F8;
$formBackground: #252525;
$placeholderColor: #B0B0B0;
$green: #50E3C2;

$colors: (
        'primary': $primary
);