@import "../_variables.scss";
@import "../mixins/breakpoints.scss";

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: $gap;
  padding-left: $gap;
}

.columns {
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);

  &.centered {
    justify-content: center;
  }

  .column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
  }

  &.force-mobile {
    display: flex;
    flex-wrap: wrap;

    .column {
      @for $i from 1 through 12 {
        &.is-#{$i} {
          flex: none;
          width: calc((#{$i} / 12) * 100%);
        }
        &.is-offset-#{$i} {
          margin-left: ($i / 12) * 100%;
        }
      }
    }
  }
}

@each $breakpoint, $container-max-width in $containers {

  @include breakpoint-min($breakpoint) {
    .container {
      max-width: $container-max-width  + (2 * $gap);
    }

    $index: nth(map_keys($breakpoints), 1);
    @if ($index == $breakpoint) {
      .columns {
        display: flex;
        flex-wrap: wrap;

        .column {
          @for $i from 1 through 12 {
            &.is-#{$i} {
              flex: none;
              width: calc((#{$i} / 12) * 100%) !important;
            }
            &.is-offset-#{$i} {
              margin-left: ($i / 12) * 100% !important;
            }
          }
        }
      }
    } @else {
      .columns {
        .column {
          @for $i from 1 through 12 {
            &.is-#{$i}-#{$breakpoint} {
              flex: none;
              width: calc((#{$i} / 12) * 100%) !important;
            }
            &.is-offset-#{$i}-#{$breakpoint} {
              margin-left: ($i / 12) * 100% !important;
            }
          }
        }
      }
    }
  }
}
