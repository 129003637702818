@import "./mixins/breakpoints.scss";
@import "./mixins/fonts.scss";
@import "./mixins/helpers.scss";

.how-section {
  background-color: black;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;

  .section-title {
    color: white;
  }

  .section-text {
    color: white;
  }
}

.globally-section {
  background-color: white;
  padding-top: 60px;

  .image {
  }
}

.how-animation {
  margin-top: 50px;
  border: solid 4px #434343;
  padding: 25px 25px 17px 25px;
}

.how-we-do-services {
  margin-top: 40px;

  .service-list {

    li {
      @include pxToRem(26);
      letter-spacing: 1px;
      cursor: pointer;
      line-height: 1.38;
      color: $darkerGray;
      @include transition(color 300ms);

      &.is-active {
        color: white;
      }
    }
  }

  .service-text-wrapper {
    position: relative;
  }

  .service-text {
    color: white;
    @include pxToRem(20);
    line-height: 1.4;
    letter-spacing: 0.5px;
  }
}

.how-mobile-services-wrapper {
  margin-top: 50px;
}

.how-mobile-services {
  color: white;
  padding-right: $gutter;
  padding-bottom: 50px;
  opacity: 0.3;
  position: relative;
  @include transition(opacity 300ms);

  h2 {
    @include pxToRem(20);
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 20px;
    white-space: pre-wrap;
  }

  .section-text {
    @include pxToRem(16);
  }

  &.slick-current {
    opacity: 1;
  }
}

.slick-dots {
  position: relative;
  top: 0;
  left: -8px;
  text-align: left;
  display: flex !important;

  .slider-dot {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 8px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;

    .slider-color {
      width: 40px;
      height: 4px;
      background-color: white;
      opacity: 0.3;
      @include transition(opacity 300ms)
    }
  }

  li {
    margin: 0;
    width: auto;
    height: auto;

    &.slick-active {

      .slider-color {
        opacity: 1;
      }
    }
  }
}

@include breakpoint-min(tablet) {
  .how-mobile-services-wrapper {
    overflow: hidden;
  }
  .how-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .globally-section {
    padding-top: 100px;
  }
  .service-text {
    position: absolute;
    top: 0;
    left: 0;
    @include transition(opacity 300ms);
  }
}

@include breakpoint-min(desktop) {
  .how-mobile-services-wrapper {
    overflow: auto;
  }
  .how-section {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .how-animation {
    margin-top: 5px;
    padding: 50px 50px 34px 50px;
  }
  .how-we-do-services {
    margin-top: 80px;
  }
  .globally-section {
    padding-top: 200px;
  }
}