@import "./_variables.scss";
@import "./mixins/fonts.scss";
@import "./mixins/helpers.scss";
@import "./mixins/breakpoints.scss";

.studio-main-section {
  background-color: black;

  .container {
    position: relative;
  }

  h1 {
    padding-bottom: 200px;
  }

  .studio-image {
    position: absolute;
    top: 90%;
    left: $gap;
    right: $gap;
    z-index: 2;
    box-shadow: 0 25px 43px -14px rgba(0, 0, 0, 0.1);
  }
}

.black-studio {
  background-color: black;
  height: 35vw;
}

.left-gallery {
  margin-top: 200px;
}

@include breakpoint-min(landscape) {
  .black-studio {
    height: 230px;
  }
}

@include breakpoint-min(tablet) {
  .black-studio {
    height: 230px;
  }
}

@include breakpoint-min(desktop) {
  .left-gallery {
    margin-top: 350px;
  }

  .black-studio {
    height: 300px;
  }

  .studio-main-section {
    h1 {
      padding-bottom: 50px;
    }
  }
}