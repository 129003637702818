@import "./mixins/fonts.scss";
@import "./mixins/breakpoints.scss";

.projects {
  overflow: hidden;
}

.project-section {
  background-color: white;
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  &.london {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  &.guessup {
    padding-top: 40px;
    padding-bottom: 40px;

    .square {
      height: 389px;
      width: 624px;
      position: absolute;
      left: 46%;
      top: 40%;
    }
  }

  &.myos {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.chocolatebox {
    padding-top: 40px;
    padding-bottom: 60px;

    .square {
      height: 409px;
      width: 634px;
      position: absolute;
      left: 44%;
      top: 12%;
    }
  }

  .donut {
    width: 631px;
    height: 631px;
    position: absolute;
    bottom: 12%;
    left: 30%;
  }

  .oval {
    width: 640px;
    height: 640px;
    position: absolute;
    bottom: 10%;
    left: 40%;
  }
}

.project-title {
  @include pxToRem(20);
  letter-spacing: 1px;
  margin-top: 40px;
}

.project-description {
  @include pxToRem(30);
  margin-top: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  white-space: pre-wrap;
  line-height: 1.1;
}

.project-services {
  @include pxToRem(16);
  margin-top: 20px;
  color: $placeholderColor;
  font-weight: 500;
  letter-spacing: 1px;
}

.project-button {
  margin-top: 10px;
  margin-bottom: 30px;

  &:hover {
    color: white;
  }

  &.is-black {
    &:hover {
      color: black;
    }
  }
}

@include breakpoint-min(tablet) {
  .project-title {
    margin-top: 20px;
  }

  .project-description {
    margin-top: 11px;
  }

  .project-section {

    &.london {
      padding-top: 60px;
      padding-bottom: 40px;
    }

    &.guessup {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &.myos {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &.chocolatebox {
      padding-top: 40px;
      padding-bottom: 60px;
    }

    .image {
      box-shadow: -23px 25px 43px -14px rgba(0, 0, 0, 0.1);
    }
  }

  .project-button {
    margin-top: 30px;
  }
}

@include breakpoint-min(desktop) {
  .project-section {
  &.london {
    padding-top: 120px !important;
    padding-bottom: 60px !important;
  }

  &.guessup {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  &.myos {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  &.chocolatebox {
    padding-top: 60px !important;
    padding-bottom: 120px !important;
  }
}

  .project-title {
    margin-top: 30px;
    @include pxToRem(26);
  }

  .project-description {
    @include pxToRem(40);
  }

  .project-services {
    @include pxToRem(20);
  }

  .project-button {
    margin-top: 65px;
    margin-bottom: 0;
  }

}

@include breakpoint-min(widescreen) {

  .project-title {
    margin-top: 50px;
  }

  .project-section {

    &.london {
      padding-top: 220px !important;
      padding-bottom: 180px !important;
    }

    &.guessup {
      padding-top: 180px !important;
      padding-bottom: 180px !important;
    }

    &.myos {
      padding-top: 180px !important;
      padding-bottom: 180px !important;
    }

    &.chocolatebox {
      padding-top: 180px !important;
      padding-bottom: 280px !important;
    }
  }

  .project-description {
    @include pxToRem(60);
  }
}