@import "./_variables.scss";
@import "./mixins/fonts.scss";
@import "./mixins/breakpoints.scss";

.contacts-main-section {
  background-color: black;

  .container {
    position: relative;

    &:not(.map-container) {
      padding-bottom: 150px;
    }
  }

  .page-title {
    padding-left: 16px;
    padding-right: 16px;
  }

  .section-text {
    margin-top: 0;
    padding-bottom: 40px;
  }

  .map-container {
    position: absolute;
    top: 90%;
    left: 0;
    right: 0;
    z-index: 2;

    .map {
      height: 217px;
      box-shadow: 0 25px 43px -14px rgba(0, 0, 0, 0.1);
    }
  }

  .button {
    margin-top: 70px;
    color: white;
    @include pxToRem(30)
  }
}

.black-contacts {
  background-color: black;
  height: 100px;
}

.contacts-section {
  background-color: white;
  padding-top: 130px;
  padding-bottom: 100px;

  .image {
    margin-top: 40px;
  }
}

@include breakpoint-min(landscape) {
  .black-contacts {
    height: 230px;
  }

  .contacts-main-section {
    .map-container {
      .map {
        height: 358px;
      }
    }
  }
}

@include breakpoint-min(tablet) {
  .black-contacts {
    height: 230px;
  }

  .contacts-main-section {

    .map-container {

      .map {
        height: 365px;
      }
    }
  }

  .contacts-section {
    padding-top: 200px;
    padding-bottom: 200px;

    .image {
      margin-top: 5px;
    }

    .section-title {
      @include pxToRem(40);
    }
  }
}

@include breakpoint-min(desktop) {
  .contacts-main-section {

    .container {

      &:not(.map-container) {
        padding-bottom: 0;
      }
    }

    .map-container {
      left: $gutter / 2;
      right: $gutter / 2;

      .map {
        height: 487px;
      }
    }
  }

  .black-contacts {
    height: 300px;
  }

  .contacts-section {
    padding-top: 250px;
    padding-bottom: 200px;
  }

  .contacts-text {
    @include pxToRem(30);
  }
}

@include breakpoint-min(widescreen) {

  .contacts-main-section {
    .map-container {
      .map {
        height: 578px;
      }
    }
  }

  .contacts-section {
    padding-top: 350px;

    .section-title {
      @include pxToRem(60);
      line-height: 1;
    }
  }
}

@include breakpoint-min(fullhd) {

  .contacts-section {
    padding-top: 450px;
  }

  .contacts-main-section {

    .map-container {

      .map {
        height: 650px;
      }
    }
  }
}