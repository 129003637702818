@import "../variables.scss";
@import "../mixins/fonts.scss";
@import "../mixins/helpers.scss";

.mobile-menu {
  display: none;
  overflow: hidden;
  z-index: 40;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  outline: 0;
  background-color: black;

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
  }

  .mobile-social {
    position: absolute;
    bottom: 0;
    left: $gap;
    display: flex;
  }

  &.is-open {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }

  a:not(.footer-icon-wrapper) {
    position: relative;
    text-align: left;
    color: white;
    display: block;
    padding: 8px 16px;
    margin-left: -16px;
    @include pxToRem(34);
    font-weight: 500;
    letter-spacing: 0.5px;
    opacity: 0.4;
    @include transition(opacity 300ms);
  }

  a:not(.footer-icon-wrapper) {
    &.is-active {
      opacity: 1;
    }
  }

  .footer-icon {
    opacity: 0.4;
    @include pxToRem(24);
  }

}

.mobile-close-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 22px;
  margin-right: -10px;
}

.mobile-close {
  @include delete;
  position: relative;
  float: right;
  top: 0;
  right: 0;
  padding: 20px;
}