@import "../_variables.scss";

@mixin pxToRem($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }

  font-size: $size; // Older browser support
  font-size: ($size / $base-font-size) * 1rem;
}

@mixin font-size($s) {
  @each $size in $sizes {
    $i: index($sizes, $s);
    @if $i == $s {
      font-size: $size !important;
    }
  }
}
