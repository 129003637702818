@import "../_variables.scss";
@import "../mixins/helpers.scss";
@import "../mixins/fonts.scss";

.input,
.textarea,
.form-select {
  @include input;
  @include transition(all 300ms);
  background-color: $formBackground;
  color: white;
  border: 1px solid $formBackground;
  border-radius: 3px;
  padding: 18px 20px;
  @include pxToRem(20);

  // Hover
  &:hover {

  }

  // Focus & Active
  &:focus,
  &:active {
    border: 1px solid $green;
  }

  // Disabled
  &:disabled {

  }

  // Placeholder
  @include placeholder {
    color: $placeholderColor;
    letter-spacing: 0.5px;
  }

}

.textarea {
  display: block;
  max-width: 100%;
  min-width: 100%;
  resize: vertical;
}

.label {
  display: block;
  font-size: inherit;
  margin-bottom: $label-margin-bottom;
}

.form-select {
  @include unselectable;
  cursor: pointer;
  @include transition(all 300ms);
  border: 1px solid #4a4a4a;
  background-color: black;
  color: $placeholderColor;
  letter-spacing: 0.5px;

  &:focus,
  &:active {
    border: 1px solid #4a4a4a;
  }

  &.is-active {
    background-color: $green;
    color: black;
  }
}