@mixin opacity($opacity) {
  opacity: $opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transform($transformation) {
  -webkit-transform: $transformation;
  -moz-transform: $transformation;
  -ms-transform: $transformation;
  -o-transform: $transformation;
  transform: $transformation;
}

@mixin placeholder {
  &::placeholder {
    @content
  }
}

@mixin control {
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  position: relative;
  vertical-align: top;

  &:focus,
  &:active {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

@mixin input {
  @include control;
  width: 100%;
  max-width: 100%;
}

@mixin button {
  @include control;
  cursor: pointer;
  white-space: nowrap;
}

@mixin loader {
  color: transparent !important;
  pointer-events: none;

  &:after {
    animation: rotate 500ms infinite linear;
    border: 2px solid black;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    width: 1em;
    position: absolute !important;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin delete {
  @include unselectable;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
  outline: none;
  vertical-align: top;
  height: 40px;
  width: 40px;
  position: fixed;
  top: 25px;
  right: 8px;

  &:before,
  &:after {
    background-color: black;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }
  &:before {
    height: 2px;
    width: 50%;
  }
  &:after {
    height: 50%;
    width: 2px;
  }
}

@mixin hamburguer($dimensions, $color) {
  cursor: pointer;
  display: block;
  height: $dimensions;
  position: relative;
  width: $dimensions;
  span {
    background-color: $color;
    display: block;
    height: 2px;
    left: calc(50% - 13px);
    position: absolute;
    transform-origin: center;
    transition-duration: 300ms;
    transition-property: all;
    width: 26px;

    &:nth-child(1) {
      top: calc(40%);
    }
    &:nth-child(2) {
      top: calc(58%);
    }
  }
  &.is-open {
    span {
      &:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-child(2) {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}