@import "./_colors.scss";

// FONTS
@font-face {
  font-family: 'CFont';
  src: url('../fonts/Font-65Md.eot');
  src: url('../fonts/Font-65Md.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Font-65Md.woff2') format('woff2'),
  url('../fonts/Font-65Md.woff') format('woff'),
  url('../fonts/Font-65Md.ttf') format('truetype'),
  url('../fonts/Font-65Md.svg#NHaasGroteskDSPro-65Md') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CFont';
  src: url('../fonts/Font-55Rg.eot');
  src: url('../fonts/Font-55Rg.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Font-55Rg.woff2') format('woff2'),
  url('../fonts/Font-55Rg.woff') format('woff'),
  url('../fonts/Font-55Rg.ttf') format('truetype'),
  url('../fonts/Font-55Rg.svg#NHaasGroteskDSPro-55Rg') format('svg');
  font-weight: normal;
  font-style: normal;
}

// BREAKPOINTS

$breakpoints: (
        landscape: 576px,
        tablet: 768px,
        desktop: 992px,
        widescreen: 1200px,
        fullhd: 1366px
);

// CONTAINER

$gap: 24px;
$containers: (
        landscape: 540px,
        tablet: 720px,
        desktop: 960px,
        widescreen: 1140px,
        fullhd: 1280px
);

// GRID

$gutter: 20px;

// FONT

$base-font-family: "CFont", sans-serif;
$base-font-size: 16px;
$base-font-weight: 400;
$base-line-height: 1.15;
$base-text-color: black;

// TYPOGRAPHY

$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;
$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7;

// BODY

$body-background: black;
$modal-background: white;

// MARGINS

$heading-margin-bottom: 0;
$p-margin-bottom: 0;
$label-margin-bottom: 0;

// IMAGES

$dimensions: 16 24 32 48 64 96 128;

// NAVBAR

$navbar-height: 3.25em;