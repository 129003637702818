@import "./mixins/breakpoints.scss";

.part-team-section {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 60px;

  .image {
    margin-top: 36px;
    box-shadow: -23px 25px 43px -14px rgba(0, 0, 0, 0.1);
  }

  .button {
    margin-top: 20px;
  }
  
  .container {
    position: relative;
    z-index: 1;
  }

  .square {
    height: 320px;
    width: 0;
    position: absolute;
    right: $gap;
    bottom: -18%;
    z-index: -1;
    transition: width 1000ms;
  }
}

@include breakpoint-min(tablet) {
  .part-team-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@include breakpoint-min(desktop) {

  .part-team-section {
    padding-top: 200px;
    padding-bottom: 220px;

    .button {
      margin-top: 72px;
    }

    .image {
      margin-top: 80px;
    }
  }

}