@import "./mixins/fonts.scss";
@import "./mixins/helpers.scss";
@import "./mixins/breakpoints.scss";

.footer {
  background-color: black;
  padding-top: 90px;
  padding-bottom: 60px;

  .section-title {
    color: white;
    @include pxToRem(32);
  }
}

.footer-logo-wrapper {
  margin-left: -8px;
  margin-bottom: 50px;

  &.with-padding {
    padding: 0 8px;
  }
}

.footer-links {
  margin-top: 60px;

  > div {
    margin-bottom: 30px;
  }
}

.footer-label {
  @include pxToRem(20);
  color: white;
  @include opacity(0.4);
  font-weight: 500;
}

.footer-button {
  @include pxToRem(22);
  color: white !important;
  user-select: all;

  &:hover {
    color: white;
  }
}

.footer-copy {
  margin-top: 80px;
  color: $darkerGray;
  font-weight: 500;
}

.footer-icon {
  @include button;
  @include unselectable;
  @include pxToRem(22);
  @include transition(color 300ms);
  color: white;
  border: none;
  position: relative;
  padding: 16px;
  margin-left: -16px;
  margin-right: 20px;
  margin-top: 6px;

  &:hover {
    svg {
      path {
        fill: $green;
      }
    }
  }

  svg {
    path {
      @include transition(fill 300ms);
      fill: white;
    }
  }
}

@include breakpoint-min(tablet) {
  .footer-logo-wrapper {
    margin-top: 0;
  }

  .footer {
    .section-title {
      @include pxToRem(40);
    }
  }
}

@include breakpoint-min(desktop) {
  .footer {
    padding-top: 180px;

    .section-title {
      @include pxToRem(60);
    }
  }

  .footer-links {
    margin-top: 110px;
  }

  .footer-button {
    @include pxToRem(26);
  }

  .footer-copy {
    margin-top: 110px;
  }
}