@import "./_all.scss";
@import "~slick-carousel/slick/slick.css";
@import "~mapbox-gl/dist/mapbox-gl.css";

.wrapper {
  position: relative;
}

.wrapper-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.page-title {
  @include pxToRem(44);
  color: white;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 1.1;
  margin-top: 180px;
  white-space: pre-wrap;
}

.section-title {
  @include pxToRem(30);
  letter-spacing: 0.5px;
  font-weight: 500;
  margin-bottom: 20px;
  white-space: pre-wrap;
  line-height: 1.1;
}

.section-text {
  @include pxToRem(16);
  letter-spacing: 1px;
  line-height: 1.35;
  white-space: pre-wrap;
}

.main-section {
  background-color: black;

  .button {
    margin-top: 30px;
    margin-bottom: 250px;
  }
}

.slick-list {
  overflow: visible;
}

.home-triangle {
  position: absolute;
  top: 20%;
  bottom: 0;
  left: 20%;
  right: 0;
}

@import "elements.scss";

@import "project.scss";
@import "what_we_do.scss";
@import "team.scss";
@import "footer.scss";

@import "studio.scss";
@import "culture.scss";
@import "how.scss";
@import "who.scss";

@import "contacts.scss";

@import "quote.scss";
@import "error.scss";

@include breakpoint-min(landscape) {
  .home-triangle {
    left: 40%;
  }
}

@include breakpoint-min(tablet) {
  .page-title {
    @include pxToRem(60);
    margin-top: 220px;
  }

  .section-title {
    @include pxToRem(40);
  }

  .section-text {
    padding-top: 5px;
    @include pxToRem(20);
    line-height: 1.25;
  }
}

@include breakpoint-min(desktop) {
  .page-title {
    @include pxToRem(80);
    margin-top: 0;
  }

  .main-section {

    .button {
      margin-top: 64px;
      margin-bottom: 0;
    }
  }

  .section-title {
    @include pxToRem(60);
  }

  .section-text {
    @include pxToRem(26);
    line-height: 1.38;

  }
}