@import "./mixins/fonts.scss";
@import "./mixins/breakpoints.scss";

.quote-section {
  background-color: black;
  padding-top: 150px;
  padding-bottom: 180px;

  .section-title {
    color: white;
    @include pxToRem(40)
  }
  .section-text {
    color: white;
    margin-top: 30px;
    @include pxToRem(16)
  }

  .quote-title {
    color: white;
    @include pxToRem(30);
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .column {
    &:not(last-child) {
      margin-bottom: 20px;
    }
  }

  .form-select {
    @include pxToRem(16)
  }

  textarea {
    min-height: 60px;
  }

  .input {
    @include pxToRem(16);
  }
}

.success-section {
  background-color: black;
  padding-top: 150px;
  padding-bottom: 180px;
  display: flex;
  align-items: center;

  .section-title {
    color: white;
    @include pxToRem(44)
  }
  .section-text {
    color: white;
    @include pxToRem(16)
  }

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  .footer-copy {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
  }
}

@include breakpoint-min(tablet) {

  .quote-section {

    .section-title {
      @include pxToRem(60)
    }

    .section-text {
      @include pxToRem(20)
    }

    .quote-title {
      @include pxToRem(40);
    }

    .input {
      @include pxToRem(20);
    }

    .form-select {
      @include pxToRem(20)
    }
  }

  .success-section {

    .section-title {
      @include pxToRem(80)
    }
    .section-text {
      @include pxToRem(26)
    }
  }
}