@import "../_variables.scss";
@import "../mixins/breakpoints.scss";
@import "../mixins/helpers.scss";

.modal {
  align-items: center;
  display: none;
  justify-content: center;
  overflow: hidden;
  z-index: 40;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  outline: 0;

  &.is-open {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal-background {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(black, 0.5);
}

.modal-content {
  @include unselectable;
  overflow: auto;
  position: relative;
  width: 100%;
  background-color: $modal-background;
  padding: $gutter;
  margin: $gap;
}

@include breakpoint-min(tablet) {
  .modal-content {
    max-width: 640px;
  }
}
