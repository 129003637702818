@import "../_colors.scss";
@import "../_variables.scss";
@import "../mixins/helpers.scss";
@import "../mixins/fonts.scss";
@import "../mixins/breakpoints.scss";

.header-burger {
  @include hamburguer($navbar-height, white);
  margin-right: 7px;

  &.menu-opened {
    span {
      &:nth-child(1) {
        left: calc(50% - 11px);
        top: calc(50%);
        @include transform(rotate(45deg));
      }
      &:nth-child(2) {
        left: calc(50% - 11px);
        top: calc(50%);
        @include transform(rotate(-45deg));
      }
    }
  }
}

.header {
  min-height: $navbar-height;
  position: relative;

  &.is-fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 16px;
    z-index: 50;
  }

  & > .container {
    align-items: stretch;
    display: flex;
    min-height: $navbar-height;
    width: 100%;
  }

  .header-left {
    align-items: stretch;
    display: flex;
    flex-shrink: 0;
    min-height: $navbar-height;
    margin-left: -$gutter;
  }

  .header-right {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: -$gutter;
  }

  &.is-black {
    .header-link {
      color: black;

      &:not(.header-title) {
        &:before {
          background-color: black !important;
        }
      }
    }

    .header-burger {
      span {
        background-color: black;
      }
    }
  }



}

html.has-header-fixed,
body.has-header-fixed {
  padding-top: $navbar-height;
}

.header-title {
  @include pxToRem(22);
}

.header-link {
  align-items: center;
  display: flex;
  padding-left: $gutter;
  padding-right: $gutter;
  position: relative;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: white;
  @include transition(color 300ms);

  &:not(.header-title):not(.quote-close) {
    &:before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: $gutter;
      right: 100%;
      height: 2px;
      z-index: -1;
      background-color: white;
      transition: all 300ms ease;
    }
  }

  &:hover {
    color: white;

    &:not(.header-title):not(.quote-close) {
      &:before {
        right: 50%;
      }
    }
  }

  &.is-active {

    &:not(.header-title):not(.quote-close) {
      &:before {
        right: $gutter;
      }
    }
  }

  &.quote-close {
    margin-right: calc(-3.25em + 35px);
  }

}

@include breakpoint-min(tablet) {
  .header-right {
    margin-right: -$gutter !important;
  }
}

@include breakpoint-min(desktop) {
  .header {

    &.is-fixed {
      top: 50px;
    }
  }
}