@import "../_variables.scss";
@import "../mixins/breakpoints.scss";
@import "../mixins/helpers.scss";
@import "../mixins/fonts.scss";

.button {
  @include button;
  @include unselectable;
  @include pxToRem(16);
  background-color: transparent;
  color: white;
  border: none;
  position: relative;
  padding: 16px;
  margin-left: -16px;
  font-weight: 500;
  letter-spacing: 0.5px;

  &:not(.form-button) {
    &:before {
      content: "";
      position: absolute;
      bottom: 10px;
      left: 16px;
      right: 16px;
      height: 2px;
      z-index: 0;
      background-color: white;
      transition: all 300ms ease;
    }
  }

  // Hover
  &:hover {
    &:not(.form-button) {
      &:before {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 16px;
        right: 50%;
        height: 2px;
        z-index: 0;
        background-color: white;
        transition: all 300ms ease;
      }
    }
  }

  // Focus
  &:focus {

  }

  // Active
  &:active {

  }

  // Loading
  &.is-loading {
    @include loader;

    &:after { // Change loading color
      border: 2px solid $primary;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

  // Disabled
  &[disabled] {

  }

  &.is-black {
    color: black;

    &:before {
      background-color: black;
    }

    &:hover {
      &:before {
        background-color: black;
      }
    }
  }

  &.form-button {
    background-color: white;
    color: black;
    margin-left: 0;
    margin-top: 85px;
    width: 100%;
    justify-content: center;
    letter-spacing: normal;
    border-radius: 3px;

    &.is-loading {

      &:after { // Change loading color
        border: 2px solid black;
        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
  }

}

@include breakpoint-min(landscape) {

  .button {

    &.form-button {
      width: 250px;
    }
  }

}

@include breakpoint-min(desktop) {
  .button {
    @include pxToRem(20);
  }
}