@import "./mixins/fonts.scss";
@import "./mixins/helpers.scss";
@import "./mixins/breakpoints.scss";

.what-we-do-section {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: black;
  position: relative;
  overflow: hidden;

  .container {
    position: relative;
    z-index: 1;
  }

  .section-title {
    color: white;
  }

  .section-text {
    color: white;
  }

  .donut-square {
    position: absolute;
    top: 56%;
    right: 65%;
    width: 985px;
    height: 985px;
  }
}

.service-list {

  li {
    @include pxToRem(20);
    letter-spacing: 1px;
    cursor: pointer;
    color: $darkerGray;
    @include transition(color 300ms);
    line-height: 1.5;

    &.is-active {
      color: white;
    }
  }
}

.service-secondary-list-wrapper {
  position: relative;
}

.service-secondary-list {
  min-height: 200px;

  li {
    @include pxToRem(16);
    color: white;
    position: relative;
    line-height: 1.4;
    padding-left: 50px;

    span {
      position: absolute;
      top: 0;
      left: 0px;
    }
  }
}

.what-we-do-services {
  margin-top: 40px;
}

@include breakpoint-min(tablet) {
  .what-we-do-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .service-secondary-list {
    min-height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    @include transition(opacity 300ms);
  }
}

@include breakpoint-min(desktop) {

  .what-we-do-section {
    padding-top: 150px;
    padding-bottom: 200px;
  }

  .what-we-do-services {
    margin-top: 80px;
  }

  .service-secondary-list {
    min-height: 200px;

    li {
      @include pxToRem(20);
    }
  }

  .service-list {

    li {
      @include pxToRem(26);
      line-height: 1.38;
    }
  }
}

@include breakpoint-min(widescreen) {

  .what-we-do-section {
    padding-top: 200px;
    padding-bottom: 250px;
  }

}